// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contacto-tsx": () => import("./../src/pages/contacto.tsx" /* webpackChunkName: "component---src-pages-contacto-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-objetivos-tsx": () => import("./../src/pages/objetivos.tsx" /* webpackChunkName: "component---src-pages-objetivos-tsx" */),
  "component---src-pages-programas-apadrina-a-un-nino-tsx": () => import("./../src/pages/programas/apadrina-a-un-nino.tsx" /* webpackChunkName: "component---src-pages-programas-apadrina-a-un-nino-tsx" */),
  "component---src-pages-programas-hilos-de-amor-tsx": () => import("./../src/pages/programas/hilos-de-amor.tsx" /* webpackChunkName: "component---src-pages-programas-hilos-de-amor-tsx" */),
  "component---src-pages-programas-index-tsx": () => import("./../src/pages/programas/index.tsx" /* webpackChunkName: "component---src-pages-programas-index-tsx" */),
  "component---src-pages-programas-te-entapamos-con-amor-tsx": () => import("./../src/pages/programas/te-entapamos-con-amor.tsx" /* webpackChunkName: "component---src-pages-programas-te-entapamos-con-amor-tsx" */),
  "component---src-pages-quiero-ayudar-dona-tsx": () => import("./../src/pages/quiero-ayudar/dona.tsx" /* webpackChunkName: "component---src-pages-quiero-ayudar-dona-tsx" */),
  "component---src-pages-quiero-ayudar-index-tsx": () => import("./../src/pages/quiero-ayudar/index.tsx" /* webpackChunkName: "component---src-pages-quiero-ayudar-index-tsx" */),
  "component---src-pages-quiero-ayudar-patrocinador-tsx": () => import("./../src/pages/quiero-ayudar/patrocinador.tsx" /* webpackChunkName: "component---src-pages-quiero-ayudar-patrocinador-tsx" */)
}

