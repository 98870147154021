import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Common from '../../components/common';

const Dona = () => {
  const [sent, setSent] = useState(false);
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(errors);

  return (
    <Layout>
      <SEO
        title="Quiero Ayudar"
        description="Puedes ser nuestro patrocinador permanente o donador de ocasión y para
        cualquiera de los dos casos tenemos recibos deducibles de impuestos, de
        igual manera si tienes una empresa de productos o servicio que pueden
        contribuir con nuestra causa también puedes hacerlo."
      />
      <Container>
        <Common />

        {sent && (
          <Success>
            <h2>¡Eso fue todo!</h2>
            Gracias por tomarte el tiempo para inscribirte a nuestro
            voluntariado, revisaremos su información y nos contactaremos contigo
            la brevedad para continuar con el proceso.
          </Success>
        )}

        <p>
          Gracias tu tiempo de vida para ayudar a familias enteras que te
          necesitan, puedes participar con nosotros en futuros eventos en zonas
          públicas, en hospitales e incluso en los hogares de nuestros pequeños,
          para esto es necesario conocer un poquito de ti, por favor ayúdanos a
          llenar este formulario:
        </p>
        <Button to="/contacto">Contáctanos</Button>
        {/* <Form onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="nombre">
            Cuál es tu nombre de pila?
            <input
              type="text"
              id="nombre"
              name="Cuál es tu nombre de pila?"
              ref={register({ required: true, min: 2 })}
            />
          </label>
          <label htmlFor="apellidos">
            Cuales son tus apellidos?
            <input
              type="text"
              id="apellidos"
              name="Cuales son tus apellidos?"
              ref={register({ required: true, min: 3 })}
            />
          </label>
          <label htmlFor="edad">
            Que edad tienes?
            <input
              type="number"
              id="edad"
              name="Que edad tienes?"
              ref={register({ required: true, max: 99, min: 16 })}
            />
          </label>
          <label htmlFor="otras">
            Has sido Voluntario en otras fundaciones? En cuáles?
            <textarea
              name="Has sido Voluntario en otras fundaciones? En cuáles?"
              id="otras"
              ref={register}
            />
          </label>
          <label htmlFor="porque">
            Porque quieres ser Voluntario AMVI?
            <textarea
              id="porque"
              name="Porque quieres ser Voluntario AMVI?"
              ref={register({ required: true })}
            />
          </label>
          <label htmlFor="docs">
            <strong>Adjunta una foto de los siguientes documentos</strong>
            <br />
            Esto con la intención de salvaguardar la seguridad e integridad de
            nuestras familias, tu también puedes estar seguro que dentro de tu
            equipo de Voluntarios se encuentran personas reales y con buenas
            intenciones como tú.
            <br />
            Identificación oficial y comprobante de domicilio
            <input
              type="file"
              id="docs"
              placeholder="Adjunta una foto de los siguientes documentos"
              name="Adjunta una foto de los siguientes documentos"
              ref={register({ required: true })}
            />
          </label>
          <label htmlFor="email">
            A que correo podemos escribirte?
            <input
              type="email"
              id="email"
              placeholder=""
              name="A que correo podemos escribirte?"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'dirección de correo electrónico inválida',
                },
              })}
            />
            {errors?.email?.message}
          </label>
          <h3>Menciona una referencia familiar:</h3>
          <p>Puede ser tú papá, mamá, esposo(a), un tío, etc</p>
          <label htmlFor="fam_nombre">
            Nombre:
            <input
              type="text"
              id="fam_nombre"
              name="Nombre:"
              ref={register({ required: true })}
            />
          </label>
          <label htmlFor="fam_direccion">
            Dirección:
            <input
              type="text"
              id="fam_direccion"
              name="Dirección:"
              ref={register({ required: true })}
            />
          </label>
          <label htmlFor="fam_tel">
            Teléfono:
            <input
              type="tel"
              id="fam_tel"
              name="Teléfono:"
              ref={register({ required: true })}
            />
          </label>
          <h3>Menciona una referencia social:</h3>
          <p>Puede ser un amigo, vecino, compañero de trabajo, etc</p>
          <label htmlFor="soc_nombre">
            Nombre:
            <input
              type="text"
              id="soc_nombre"
              name="Nombre:"
              ref={register({ required: true })}
            />
          </label>
          <label htmlFor="soc_direccion">
            Dirección:
            <input
              type="text"
              id="soc_direccion"
              name="Dirección:"
              ref={register({ required: true })}
            />
          </label>
          <label htmlFor="soc_tel">
            Teléfono:
            <input
              type="tel"
              id="soc_tel"
              name="Teléfono:"
              ref={register({ required: true })}
            />
          </label>
          <p>
            <strong>En qué programa te gustaría participar:</strong>
          </p>
          <label htmlFor="fondos">
            <input
              type="checkbox"
              id="fondos"
              placeholder="Recaudación de fondos"
              name="Recaudación de fondos"
              ref={register}
            />
            Recaudación de fondos
          </label>
          <label htmlFor="acompanamiento">
            <input
              type="checkbox"
              id="acompanamiento"
              placeholder="Acompañamiento Hospitalario"
              name="Acompañamiento Hospitalario"
              ref={register}
            />
            Acompañamiento Hospitalario
          </label>
          <label htmlFor="fiestas">
            <input
              type="checkbox"
              id="fiestas"
              placeholder="Organización de fiestas"
              name="Organización de fiestas"
              ref={register}
            />
            Organización de fiestas
          </label>
          <label htmlFor="suenos">
            <input
              type="checkbox"
              id="suenos"
              placeholder="Organización de Cumplimiento de Sueños"
              name="Organización de Cumplimiento de Sueños"
              ref={register}
            />
            Organización de Cumplimiento de Sueños
          </label>
          <label htmlFor="dia">
            <input
              type="checkbox"
              id="dia"
              placeholder="Día AMVI"
              name="Día AMVI"
              ref={register}
            />
            Día AMVI
          </label>

          <button type="submit">Enviar</button>
        </Form> */}
      </Container>
    </Layout>
  );
};

const Form = styled.form`
  label,
  input:not([type='checkbox']),
  textarea {
    display: block;
    line-height: 1.5rem;
  }

  input:not([type='checkbox']),
  textarea {
    width: 80%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    padding: 0.25rem 0.5rem;
  }

  textarea {
    height: 6em;
  }

  [type='checkbox'] {
    margin-right: 0.5rem;
  }

  button {
    border: none;
    background: #ff2a58;
    color: #fff;
    min-width: 33%;
    padding: 0.5rem 1rem;
    margin-top: 2rem;
    border-radius: 99px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Success = styled.div`
  color: #236e56;
  background: #84dfc1;
  margin: 1rem 0;
  padding: 0.5rem;
  border: 1px solid #236e56;
  border-radius: 0.5rem;

  h2 {
    font-size: 1.75rem;
    margin-bottom: 0.5em;
  }
`;

const Button = styled(Link)`
  background: #ff2a58;
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 99px;
  font-weight: bold;
  text-transform: uppercase;
`;

export default Dona;
