import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import { Row, Column } from '../components/grid';

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Casa" />
    <Img fluid={data.headerImage.childImageSharp.fluid} />
    <Donate>
      <Container>
        <h2>Somos fundación AMVI A.C.</h2>
        <p>
          Somos una asociación sin fines de lucro que nace del amor a la
          humanidad creada para ofrecer ayuda integral a niños y jóvenes que
          padecen alguna enfermedad crónico degenerativa y a todos los miembros
          de su núcleo familiar.
        </p>
        <Button to="/quiero-ayudar">Contribuir ahora &raquo;</Button>
      </Container>
    </Donate>
    <Container>
      <p>
        AMVI está diseñada para trabajar mediante convenios de colaboración con
        instituciones públicas y privadas, asociaciones civiles del Estado de
        México, nacionales e internacionales, con la certeza de que unidos
        hacemos más!
      </p>
      <h2>Nuestra misión</h2>
      <p>
        Incrementar la posibilidad y calidad de vida de niños y jóvenes con
        alguna enfermedad crónico, degenerativa mediante la atención y
        desarrollo físico, psicológico, social, y emocional y de la misma manera
        a cada integrante de su núcleo familiar.
      </p>
      <Row>
        <Column className="half">
          <GridImg fluid={data.creamos.childImageSharp.fluid} />
        </Column>
        <Column className="half">
          <h2>Creamos</h2>
          <p>
            Creamos programas artísticos, deportivos, culturales, de
            acompañamiento hospitalario, desarrollo humano y escolares para
            mejorar la integración social entre el paciente y su familia y de
            ambis con la sociedad.
          </p>
        </Column>
      </Row>
      <Row className="reverse">
        <Column className="half">
          <GridImg fluid={data.cumplimos.childImageSharp.fluid} />
        </Column>
        <Column className="half">
          <h2>Cumplimos sueños</h2>
          <p>
            Ropa, juguetes, celebraciones, viajes, excursiones, conocer a... de
            esta manera sembramos la esperanza de que todo es posible.
          </p>
        </Column>
      </Row>
      <Row>
        <Column className="half">
          <GridImg fluid={data.ayudamos.childImageSharp.fluid} />
        </Column>
        <Column className="half">
          <h2>Otorgamos</h2>
          <p>
            Tratamientos inmunológicos, tratamiento psicológicos, estudios
            especializados, pelucas oncológicas, tanques de oxígeno.
          </p>
        </Column>
      </Row>
      <Center>
        <p>¿Conoces a alguien que necesite nuestra ayuda?</p>
        <Button to="/contacto">Contáctanos</Button>
      </Center>
    </Container>
  </Layout>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    headerImage: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    creamos: PropTypes.object.isRequired,
    cumplimos: PropTypes.object.isRequired,
    ayudamos: PropTypes.object.isRequired,
  }).isRequired,
};

export default IndexPage;

export const query = graphql`
  query {
    headerImage: file(relativePath: { regex: "/amvi.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    creamos: file(relativePath: { regex: "/creamos.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cumplimos: file(relativePath: { regex: "/cumplimos.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 80, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ayudamos: file(relativePath: { regex: "/ayudamos.jpg/" }) {
      childImageSharp {
        fluid(cropFocus: CENTER, quality: 100, maxWidth: 1920, maxHeight: 600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Center = styled.div`
  text-align: center;
`;

const Donate = styled.section`
  background: #3678af;
  color: #fff;
  padding: 2rem 0;
  margin-bottom: 3rem;
  text-align: center;
`;

const Button = styled(Link)`
  background: #ff2a58;
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 99px;
  font-weight: bold;
  text-transform: uppercase;
`;

const GridImg = styled(Img)`
  height: 300px;
  border-radius: 0.5rem;
`;
