import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const Navigation = () => (
  <StyledNavigation>
    <Menu>
      <MenuItem>
        <Link to="/nosotros" activeClassName="active">
          Nosotros
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/objetivos" activeClassName="active">
          Objetivos
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/programas" activeClassName="active" partiallyActive>
          Programas
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/quiero-ayudar" activeClassName="active" partiallyActive>
          Quiero Ayudar
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to="/contacto" activeClassName="active">
          Contacto
        </Link>
      </MenuItem>
      <Button>
        <Link to="/quiero-ayudar/dona" role="button">
          Contribuir
        </Link>
      </Button>
    </Menu>
  </StyledNavigation>
);

export default Navigation;

const StyledNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  margin: 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MenuItem = styled.li`
  padding: 1rem 0.5rem;
  margin: 0;
`;

const Button = styled.li`
  padding: 1rem 0.5rem;
  margin: 0;
  a {
    background: #ff2a58;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 99px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;
