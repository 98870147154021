import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Common from '../../components/common';

const Dona = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(errors);

  return (
    <Layout>
      <SEO
        title="Dona"
        description="Puedes ser nuestro patrocinador permanente o donador de ocasión y para
        cualquiera de los dos casos tenemos recibos deducibles de impuestos, de
        igual manera si tienes una empresa de productos o servicio que pueden
        contribuir con nuestra causa también puedes hacerlo."
      />
      <Container>
        <Common />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <p>
            Gracias por donar tu tiempo de vida y el fruto de tu trabajo para
            ayudar a familias enteras que te necesitan.
          </p>
          <Button to="/contacto">Contáctanos</Button>
        </Form>
      </Container>
    </Layout>
  );
};

const Form = styled.form`
  label,
  input,
  textarea {
    display: block;
  }

  input,
  textarea {
    width: 80%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    padding: 0.25rem 0.5rem;
  }

  textarea {
    height: 6em;
  }

  button {
    border: none;
    background: #ff2a58;
    color: #fff;
    min-width: 33%;
    padding: 0.5rem 1rem;
    border-radius: 99px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Button = styled(Link)`
  background: #ff2a58;
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 99px;
  font-weight: bold;
  text-transform: uppercase;
`;

export default Dona;
