import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import Container from '../../components/container';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Common from '../../components/common';

const Patrocinador = () => {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(errors);

  return (
    <Layout>
      <SEO
        title="Patrocinador"
        description="Puedes ser nuestro patrocinador permanente o donador de ocasión y para
        cualquiera de los dos casos tenemos recibos deducibles de impuestos, de
        igual manera si tienes una empresa de productos o servicio que pueden
        contribuir con nuestra causa también puedes hacerlo."
      />
      <Container>
        <Common />

        <Form onSubmit={handleSubmit(onSubmit)}>
          <p>
            Gracias por tu interés en sumarte a nuestra causa, cientos de
            familias del estado de México requieren que empresas como la tuya se
            unan a su batalla, seguramente ustedes cuántas con herramientas que
            les sirven a ellos para luchar fuertes cada dia.
          </p>
          <p>
            A continuación te pediremos alguna información que nos ayudará a
            agilizar el proceso
          </p>
          <Button to="/contacto">Contáctanos</Button>
          {/* <input
            type="text"
            placeholder="Cuál es tu nombre? (Completo)"
            name="Cuál es tu nombre? (Completo)"
            ref={register({ required: true })}
          />
          <input
            type="text"
            placeholder="Cual es el nombre de tu empresa?"
            name="Cual es el nombre de tu empresa?"
            ref={register}
          />
          <input
            type="text"
            placeholder="Cual es el Giro de tu empresa?"
            name="Cual es el Giro de tu empresa?"
            ref={register}
          />
          <textarea
            name="De manera general, cuéntanos si tienes un proyecto en mente o si tienes recursos que consideras que nos pueden ser útiles. Puedes omitir este paso si quieres que te contáctenos para darte ideas de acuerdo a tu giro y capacidades."
            ref={register}
          />
          <input
            type="email"
            placeholder="A que correo podemos escribirte?"
            name="A que correo podemos escribirte?"
            ref={register({ required: true })}
          />
          <input
            type="tel"
            placeholder="A qué número telefónico podemos marcarte?"
            name="A qué número telefónico podemos marcarte?"
            ref={register({ required: true })}
          />

          <input
            name="Que tipo de colaboración quieres tener con nosotros?"
            type="radio"
            value="Patrocinador de Eventos"
            ref={register({ required: true })}
          />
          <input
            name="Que tipo de colaboración quieres tener con nosotros?"
            type="radio"
            value="Donador en Especie"
            ref={register({ required: true })}
          />
          <input
            name="Que tipo de colaboración quieres tener con nosotros?"
            type="radio"
            value="Donador Monetario"
            ref={register({ required: true })}
          />
          <input
            name="Que tipo de colaboración quieres tener con nosotros?"
            type="radio"
            value="Acompañamiento con tu personal"
            ref={register({ required: true })}
          /> */}
        </Form>
      </Container>
    </Layout>
  );
};

const Form = styled.form`
  label,
  input,
  textarea {
    display: block;
  }

  input,
  textarea {
    width: 80%;
    margin-bottom: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    padding: 0.25rem 0.5rem;
  }

  textarea {
    height: 6em;
  }

  button {
    border: none;
    background: #ff2a58;
    color: #fff;
    min-width: 33%;
    padding: 0.5rem 1rem;
    border-radius: 99px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const Button = styled(Link)`
  background: #ff2a58;
  font-size: 1.2rem;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 99px;
  font-weight: bold;
  text-transform: uppercase;
`;

export default Patrocinador;
